export const RECOMMENDATIONS_COUNT = 4;

// Education difficulty level mapping
export const EDUCATION_LEVEL_MAPPING = {
  p: 'Advanced',
  m: 'Advanced',
  b: 'Intermediate',
  a: 'Intermediate',
  hs: 'Introductory',
  jhs: 'Introductory',
};
